<template>
  <div class="req-temp-container client-user-form">
    <!-- user role card starts here -->
    <vs-row class="mt-4">
      <vs-col vs-w="12">
        <vs-card>
          <div slot="header">
            <h3 class="font-normal">User roles <span class="mid-blue">*</span></h3>
          </div>
          <!-- radio choose role -->
          <vs-row>
            <!-- admin -->
            <vs-col class="radioButtonCard w-auto mr-5" v-if="!isEdit() || !isApiUser(formData.userType)">
              <div class="radio-card my-5" @click="activate('staffAdmin')" :class="{ active: userRoleForCss == 'staffAdmin' }">
                <vs-row>
                  <vs-col vs-w="10" vs-sm="10" vs-lg="10" vs-xs="10" class="radio-label">Admin</vs-col>
                  <vs-col vs-w="2" vs-sm="2" vs-lg="2" vs-xs="2">
                    <vs-radio v-model="formData.userRole" vs-value="staffAdmin" vs-name="userRole" :disabled="disableRoleOption"></vs-radio>
                  </vs-col>
                </vs-row>

                <vs-row>
                  <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                    <div>
                      <span class="radio-info">Full access including editing of global account settings</span>
                    </div>
                  </vs-col>
                </vs-row>
              </div>
            </vs-col>

            <!-- User  -->
            <vs-col class="radioButtonCard w-auto lg:mr-5" v-if="!isEdit() || !isApiUser(formData.userType)">
              <div class="radio-card my-5" @click="activate('staff')" :class="{ active: userRoleForCss == 'staff' }">
                <vs-row>
                  <vs-col vs-w="10" vs-sm="10" vs-lg="10" vs-xs="10" class="radio-label"> User</vs-col>
                  <vs-col vs-w="2" vs-sm="2" vs-lg="2" vs-xs="2" class="flex items-center justify-center">
                    <vs-radio v-model="formData.userRole" vs-value="staff" vs-name="userRole" :disabled="disableRoleOption"></vs-radio>
                  </vs-col>
                </vs-row>

                <vs-row>
                  <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                    <div>
                      <span class="radio-info">Granted permissions based on user groups they are added to</span>
                    </div>
                  </vs-col>
                </vs-row>
              </div>
            </vs-col>

            <!-- User  -->
            <vs-col class="radioButtonCard w-auto lg:mr-5" v-if="!isEdit() || isApiUser(formData.userType)">
              <div class="radio-card my-5" @click="activate('apiUser')" :class="{ active: userRoleForCss == 'apiUser' }">
                <vs-row>
                  <vs-col vs-w="10" vs-sm="10" vs-lg="10" vs-xs="10" class="radio-label">API User</vs-col>
                  <vs-col vs-w="2" vs-sm="2" vs-lg="2" vs-xs="2" class="flex items-center justify-center">
                    <vs-radio v-model="formData.userRole" vs-value="apiUser" vs-name="userRole" :disabled="disableRoleOption"></vs-radio>
                  </vs-col>
                </vs-row>

                <vs-row>
                  <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                    <div>
                      <span class="radio-info">Access via API only - granted permissions based on user groups the account is added to</span>
                    </div>
                  </vs-col>
                </vs-row>
              </div>
            </vs-col>
          </vs-row>
        </vs-card>
      </vs-col>
    </vs-row>

    <!-- user role card ends here -->

    <!-- user details card starts here -->
    <vs-row>
      <vs-col vs-w="12">
        <vs-card v-if="formData.userRole != 'apiUser'">
          <div slot="header">
            <h3 class="font-normal">User details <span class="mid-blue">*</span></h3>
          </div>
          <vs-row>
            <vs-col vs-w="6" vs-lg="4" vs-sm="12">
              <div class="input-container mb-5">
                <span class="text-danger text-sm" v-show="errors.has('fullName')" >{{ errors.first("fullName") }}</span>
                <vs-input v-validate="'required'" v-model="formData.fullName" name="fullName" :v-validate="'fullNameRules'"/>
                <label class="w-full text-base">Full Name<span class="mid-blue">*</span></label>
              </div>
            </vs-col>
          </vs-row>

          <vs-row>
            <vs-col vs-w="6" vs-lg="4" vs-sm="12" class="lg:mb-5 md:mb-5 mb-5">
              <div class="input-container">
                <span class="text-danger text-sm" v-show="errors.has('email')" >{{ errors.first("email") }}</span>
                <vs-input class="w-full" v-validate="'required|email'" size="large" v-model="formData.email" name="email" />
                <label class="w-full text-base">Email <span class="mid-blue">*</span></label>
              </div>
            </vs-col>

            <vs-col vs-w="6" vs-lg="4" vs-sm="12" v-if="formData.userRole == 'staffAdmin'">
              <div class="input-container">
                <span class="text-danger text-sm" v-show="errors.has('phoneNumber')">{{ errors.first("phoneNumber") }}</span>
                <the-mask
                  class="lg:w-3/4 w-full md:w-3/4"
                  :class=" formData.phoneNumber ? 'vs-inputx vs-input--input large' : 'vs-inputx vs-input--input large'"
                  :mask="[checkPhoneNumber]"
                  v-model="formData.phoneNumber"
                  masked
                  name="phoneNumber"
                  ref="phoneNumber"
                  v-validate="'phoneFormat'"
                />

                <label class="w-full text-base">Phone number</label>
              </div>
            </vs-col>

            <vs-col vs-w="6" vs-lg="4" vs-sm="12" v-else>
              <div class="input-container">
                <span class="text-danger text-sm" v-show="errors.has('phoneNumber')">{{ errors.first("phoneNumber") }}</span>
                <the-mask
                  class="lg:w-3/4 w-full md:w-3/4"
                  :class="formData.phoneNumber ? 'vs-inputx vs-input--input large'  : 'vs-inputx vs-input--input large'"
                  :mask="[checkPhoneNumber]"
                  v-model="formData.phoneNumber"
                  masked
                  name="phoneNumber"
                  ref="phoneNumber"
                  v-validate="'phoneFormat'"
                />

                <label class="w-full text-base">Phone number </label>
              </div>
            </vs-col>
          </vs-row>
        </vs-card>
      </vs-col>
    </vs-row>

    <!-- user details card ends here -->

    <!-- api user details card starts here -->
    <vs-row>
      <vs-col vs-w="12">
        <vs-card v-if="formData.userRole == 'apiUser'">
          <div slot="header">
            <h3 class="font-normal">Details <span class="mid-blue">*</span></h3>
          </div>
          <vs-row>
            <div class="input-grp mb-5">
              <label class="w-full">System name</label>
              <vs-input class="w-full" v-validate="'required'" size="large" v-model="formData.systemName" name="systemName"/>
              <span class="text-danger text-sm" v-show="errors.has('systemName')">{{ errors.first("systemName") }}</span>
            </div>

            <div class="input-grp mb-5">
              <label class="w-full">Description</label>
              <vs-textarea class="mb-1 w-15" counter="300" maxlength="300" :counter-danger.sync="counterDanger" v-model="formData.description" rows="2"/>
            </div>
          </vs-row>
        </vs-card>
      </vs-col>
    </vs-row>

    <!-- api user details card ends here -->

    <!-- user groups starts here -->
    <vs-row v-if="formData.userRole == 'staff' || formData.userRole == 'staffAdmin'">
      <vs-col vs-w="12" v-if="merchantGroups.length > 0">
        <vs-card>
          <div slot="header">
            <h3 class="font-normal">Group Access</h3>
          </div>
          <vs-row>
            <p>Select the groups this user will require access to. Group access means..... not your admin priveledges</p>
          </vs-row>

          <vs-row class="mt-3 mb-8">
            <vs-select placeholder="Select Groups" multiple autocomplete class="selectExample" v-model="selectedGroups" @change="checkSelected">
              <div class="selectAllDiv">
                <a class="dropdownSelectAllButton" @click="selectAllGroups">{{ !allGroupsSelected ? `Select all` : `Unselect all` }}</a>
              </div>
              <vs-select-item :key="index" :value="group" :text="group.groupName" v-for="(group, index) in merchantGroups"/>
            </vs-select>
          </vs-row>

          <hr class="line-hr mb-8" />

          <!-- groups card -->

          <vs-row>
            <vs-card class="groupCard mr-5" v-for="(group, indextr) in selectedGroups" :key="indextr">
              <span class="element"><a @click="closeGroup(indextr)"><x-icon size="1.5x" class="custom-class"></x-icon></a></span>
              <vs-row>
                <h3 class="font-normal text-xl">{{ group.groupName }}</h3>
              </vs-row>
              <vs-row class="mt-3">
                <p class="text-sm"><span>{{ group.users.length }}</span>{{ group.users.length > 1 ? "users" : "user" }} have access to:</p>
              </vs-row>
              <vs-row class="mt-6">
                <p class="text-sm text-black"><span class="numberRT mr-2 text-base font-semibold">{{ group.permissionsOnRequestTemplate.length }}</span> Request templates </p>
              </vs-row>
              <vs-row class="pl-6 mt-2">
                <p v-for="(rt, i) in group.permissionsOnRequestTemplate" :key="i">{{ `${rt.templateName} , ` }}</p>
              </vs-row>
              <vs-row class="mt-4">
                <p class="text-sm text-black"><span class="numberRT mr-2 text-base font-semibold">{{ group.permissionsOnRequestTemplate.length }}</span> Hosted payment pages </p> </vs-row>
              <vs-row class="pl-6 mt-2">
                <p v-for="(hp, i) in group.permissionsOnHostedPages" :key="i">{{ `${hp.templateName} , ` }}</p>
              </vs-row>
            </vs-card>
          </vs-row>

        </vs-card>
      </vs-col>
    </vs-row>
    <vs-card class="mt-6" v-if="formData.userRole === 'apiUser' && $route.params.id">
      <div slot="header">
        <h3 class="font-normal">Third party access token</h3>
      </div>
      <vs-row>
        <div class="flex items-center">
          <vs-input class="w-full token" v-model="apiUserToken" disabled style="margin-right: 40px"/>
          <span @click="copyTokenToClipboard">
            <vs-tooltip text="Copy to clipboard" class="pointer">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M20 9H11C9.89543 9 9 9.89543 9 11V20C9 21.1046 9.89543 22 11 22H20C21.1046 22 22 21.1046 22 20V11C22 9.89543 21.1046 9 20 9Z"
                  stroke="#757575"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5 15H4C3.46957 15 2.96086 14.7893 2.58579 14.4142C2.21071 14.0391 2 13.5304 2 13V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H13C13.5304 2 14.0391 2.21071 14.4142 2.58579C14.7893 2.96086 15 3.46957 15 4V5"
                  stroke="#757575"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </vs-tooltip>
          </span>
        </div>
      </vs-row>
    </vs-card>

    <!-- user groups ends here -->

    <vs-row class="mt-8">
      <vs-col vs-w="12" class="flex justify-end justify-end">
        <vs-button size="large" class="primary mr-8" @click="saveUser" v-round>Save</vs-button>
        <vs-button
         v-if="userID && (user.userType === 'admin' || user.userType === 'staffAdmin')
          && (formData.userType === 'staff' || formData.userType === 'staffAdmin' || formData.userType === 'apiUser')
          && (user._id !== userID)"
         size="large"
         @click="popupDelActive = true"
         class="only-border-btn mr-8"
         v-round>
        Delete user
        </vs-button>
        <a color="danger" @click="onCancel" class="edit-profile flex items-center text-base" v-round>Cancel</a>
      </vs-col>
    </vs-row>

    <leave-popup :popup-active="popupActive" @handleClick="popActions"></leave-popup>

    <pop-up :errorsList="errors" form-name="UserForm"></pop-up>

    <delete-popup :popup-active="popupDelActive" :warningContent="warningContent" :deleteWhat="deleteWhat" :buttonText="buttonText" :found="warningFound" @handleDeleteClick="delActions"></delete-popup>

  </div>
</template>
<script>
import { mapActions } from "vuex";
import { Validator } from "vee-validate";
import { TheMask } from "vue-the-mask";
import LeavePopup from "@/views/components/LeavePopup";
import PopUp from "@/views/components/ValidationPopup.vue";
import { XIcon } from "vue-feather-icons";
import DeletePopup from "@/views/components/DeletePopup";
const pattern = require("@/assets/images/pattern.png");
import { leavePopUpMixin } from "@/mixins/leave-pop-up";

const dictionary = {
  en: {
    attributes: {
      phoneNumber: "phone number",
      firstName: "first name",
      lastName: "last name",
      fullName: "full name",

    },
    messages: {
      regex: () => "Phone number should start with 04 and should be 10 digits",
    },
  },
};
Validator.localize(dictionary);
export default {
  mixins: [leavePopUpMixin],
  components: { TheMask, PopUp, LeavePopup, XIcon, DeletePopup },
  data() {
    return {
      apiUserToken: "",
      userID: "",
      merchantGroups: [],
      selectedGroups: [],
      pattern: pattern,
      allGroupsSelected: false,
      userRoleForCss: "staffAdmin",
      serverUrl: process.env.VUE_APP_API_URL,
      popupActive: false, // determines if the leave popup is to be shown
      showValidationPopUp: false, // determines if the leave popup is to be shown
      isSaved: false,
      nextObj: "", // determines the next route,
      counterDanger: "",
      formData: {
        group: [],
        userRole: "staffAdmin",
        userType: "admin",
        phoneNumber: "",
        email: "",
        fullName: "",
        systemName: "",
        description: "",
      },
      initialGroups: [],
      addGroups: [],
      removeGroups: [],
      popupDelActive: false,
      warningFound: true,
      warningContent: "By deleting this user you are permanently removing their access to this platform.",
      deleteWhat: "user",
      buttonText: "Delete user",
      id: "",
      checkFormDirty: false,
      disableRoleOption: false,
      selectedUser:''
    };
  },
  methods: {
    ...mapActions("page", ["fetchPagesById", "updatePageById", "createPage"]),
    ...mapActions("partner", [
      "fetchStaffDetailsById",
      "updateStaffDetailsById",
      "createStaff",
      "deleteStaff",
    ]),
    ...mapActions("merchantGroup", ["fetchMerchantGroupByMerchantId"]),
    ...mapActions("admin", ["checkLogin"]),

    async getMerchantGroups() {
      await this.fetchMerchantGroupByMerchantId(this.partnerId)
        .then((res) => {
          this.merchantGroups = res.data.data.docs;
        })
        .catch((err) => {});
    },

    copyTokenToClipboard() {
      navigator.clipboard.writeText(this.apiUserToken).then(() => {
        this.$vs.notify({
          title: "Copy to Clipboard",
          text: "Copied text to clipboard",
          color: "success",
          iconPack: "feather",
          position: "top-right",
          icon: "icon-check-circle",
        });
      });
    },

    onCancel() {
      this.isSaved = true;
      this.$router.push({ name: "partner-users" });
    },

    activate(el) {
      if(this.disableRoleOption) return;
      this.formData.userRole = el;
      this.userRoleForCss = el;
      this.checkFormDirty = true;
    },

    closeGroup(index) {
      if (index > -1) {
        this.selectedGroups.splice(index, 1);
      }
      this.checkFormDirty = true;
    },

    selectAllGroups() {
      this.allGroupsSelected = !this.allGroupsSelected;
      if (this.allGroupsSelected) {
        this.selectedGroups = [...this.merchantGroups];
      } else {
        this.selectedGroups = [];
      }
    },
    checkSelected() {
      if (this.selectedGroups.length == this.merchantGroups.length) {
        this.allGroupsSelected = true;
      } else {
        this.allGroupsSelected = false;
      }
    },

    async getUserById(id) {
      await this.fetchStaffDetailsById(id).then((result) => {
        this.selectedUser = result.data.data;
        this.formData.fullName = result.data.data.fullName;
        this.formData.email = result.data.data.email;
        this.formData.address = result.data.data.address;
        this.formData.phoneNumber = result.data.data.phoneNumber;
        this.formData.userRole = result.data.data.userType === "admin" ? "staffAdmin" : result.data.data.userType;
        this.formData.userType = result.data.data.userType;
        this.userRoleForCss = result.data.data.userType === "admin" ? "staffAdmin" : result.data.data.userType;
        this.selectedGroups = result.data.data.group;
        this.formData.systemName = result.data.data.systemName;
        this.formData.description = result.data.data.description;
        this.apiUserToken = result.data.data.thirdPartyAcessToken;
        // Disbale role option if user is admin
        if (result.data.data.userType === "admin") {
          this.disableRoleOption = true;
        }

        let selectgrp = [];
        this.merchantGroups.forEach((element) => {
          this.selectedGroups.forEach((grdata) => {
            if (grdata._id === element._id) {
              selectgrp.push(grdata);
            }
          });
          const found = selectgrp.some((el) => el._id === element._id);
          if (!found) {
            selectgrp.push(element);
          }
        });
        this.merchantGroups = [...selectgrp];
        this.initialGroups = [...this.selectedGroups];
      }).catch((ex) => {
        if ([404, 400].includes(ex.response.status)) {
          this.$vs.notify({
            title: "Staff",
            text: ex.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-alert-circle",
          });
          this.$router.push({ name: "partner-users" });
        }
      });
    },

    async saveUser() {
      this.$vs.loading();
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          this.removeGroups = this.initialGroups.filter(
            (group) => !this.selectedGroups.includes(group)
          );
          this.formData.removeGroups = this.removeGroups;
          this.addGroups = this.selectedGroups.filter((group) => !this.initialGroups.includes(group));
          this.formData.addGroups = this.addGroups;
          this.formData.accountStatus = "Active";
          this.formData.group = this.selectedGroups.map((element) => { return element._id; });

          // Updated user role if selected user is super admin
          if (this.selectedUser && this.selectedUser.userType === "admin") {
            this.formData.userRole = "admin";
          }
          if (this.formData.userRole == "apiUser") {
            delete this.formData.email;
          }
          let obj = {
            id: this.$route.params.id,
            data: this.formData,
          };

          if (this.$route.params.id) {
            if (this.user && this.user.adminId && this.user.loginBy) {
              obj.data.editedByAdmin = this.user.adminId;
            }
            this.updateUser(obj);
          } else {
            if (this.user && this.user.adminId && this.user.loginBy) {
              obj.data.createdByAdmin = this.user.adminId;
            }
            this.storeUser(obj);
          }

          this.isSaved = true;
          this.$vs.loading.close();
        }
        this.$vs.loading.close();
      });
      this.$vs.loading.close();
    },

    async updateUser(obj) {
      this.$vs.loading();
      await this.updateStaffDetailsById(obj).then((result) => {
        this.$vs.loading.close();
        this.showMessage("Successful", "User has been updated successfully.", "success", "icon-check-circle");
        this.isSaved = true;
        this.$router.push({ name: "partner-users" });
      }).catch((ex) => {
        if (ex.response.status == 422 && ex.response.data.title == "Email") {
          this.errors.add({
            "field" : "email",
            "msg": ex.response.data.message
          });
        } else {
          this.showMessage("Error", ex.response.data.message, "danger", "icon-times");
        }
        this.$vs.loading.close();
      });
    },

    async storeUser(obj) {
      this.$vs.loading();
      await this.createStaff(obj)
        .then((result) => {
          this.$vs.loading.close();
          this.showMessage("Successful", "User has been created successfully.", "success", "icon-check-circle");
          this.isSaved = true;
          this.$router.push({ name: "partner-users" });
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.showMessage("Error", err.response.data.message, "danger", "icon-times");
        });
    },

    showMessage(title = "Successful", message = "Task completed", color = "success", icon = "icon-check-circle") {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon,
      });
    },

    delActions(event) {
      switch (event) {
        case "delete":
          this.popupDelActive = false;
          this.handleDelete();
          break;
        case "cancel":
          this.popupDelActive = false;
          break;
        default:
          this.popupDelActive = false;
      }
    },

    async handleDelete() {
      this.$vs.loading();
      await this.deleteStaff(this.id)
        .then((result) => {
          this.isSaved = true;
          this.$vs.loading.close();
          this.showMessage("Successful", result.data.message, "success", "icon-check-circle");
          this.$router.push({ name: "partner-users" });
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.showMessage("Error", err.response.data.message, "danger", "icon-times");
          console.log(err);
        });
    },

    async checkAdminLogin() {
      await this.checkLogin();
    },
    isApiUser(userType){
      return userType === "apiUser"
    },
    isEdit(){
      return this.$route.params.hasOwnProperty('id') && this.$route.params.id.length > 0;
    }
  },

  beforeRouteLeave(to, from, next) {
    if ((this.isFormDirty || this.checkFormDirty) && !this.isSaved) {
      this.nextObj = next;
      this.popupActive = true;
      localStorage.setItem("redirectTo", to.name || undefined);
    } else {
      next();
    }
  },
  watch: {
    isFormDirty(latest, previous) {
      if (latest) localStorage.setItem("formFieldUpdated", "yes");
    },

    isSaved(latest, previous) {
      if (!latest) localStorage.setItem("formFieldUpdated", "yes");
    },

    checkFormDirty(latest, previous) {
      if (latest) localStorage.setItem("formFieldUpdated", "yes");
    },
  },

  mounted() {
    if (this.$route.params.id) {
      this.userID = this.$route.params.id;
      this.getUserById(this.$route.params.id);
    }
    this.getMerchantGroups();
    this.checkAdminLogin();
  },
  created() {
    this.$validator.extend("fullNameRules", {
      getMessage: () => "The name should consist at least two words.",
      validate: (value) => value.match(/^[a-z]+(?:\s[a-z]+)+$/) !== null,
    });

    this.$validator.extend("phoneFormat", {
      getMessage: () => "The phone number format is invalid.",
      validate: (value) =>
        value.match(/^(^04\(?\d{2}\)?[\s.-]\d{3}[\s.-]\d{3})|(^1300[\s.-]\d{3}[\s.-]\d{3})|(^1800[\s.-]\d{3}[\s.-]\d{3})|(^13[\s.-]\d{2}[\s.-]\d{2}$)|(^02[\s.-]\d{4}[\s.-]\d{4})|(^03[\s.-]\d{4}[\s.-]\d{4})|(^07[\s.-]\d{4}[\s.-]\d{4})|(^08[\s.-]\d{4}[\s.-]\d{4})/) !== null,
    });

    this.$validator.extend("mobileFormat", {
      getMessage: () => "The mobile phone number format is invalid.",
      validate: (value) =>
        value.match(/^(^04\(?\d{2}\)?[\s.-]\d{3}[\s.-]\d{3})/) !== null,
    });
    this.id = this.$route.params.id;
  },

  computed: {
    user() {
      return this.$store.state.AppActiveUser;
    },

    leavePage() {
      return this.popupAnswer;
    },

    partnerId() {
      return this.user.userType === "admin"
        ? this.user._id
        : this.user.partnerId;
    },

    checkPhoneNumber() {
      let mask = "#### ### ###";
      if (this.formData.phoneNumber && this.formData.phoneNumber.length > 0) {
        let numLength = this.formData.phoneNumber.length;
        let value1 = "";
        let value2 = "";
        if (numLength >= 2) {
          value1 = this.formData.phoneNumber.substring(0, 2);
          if (value1 === "13") {
            value2 = this.formData.phoneNumber.substring(0, 4);
          }
        }

        if (value1 === "02" || value1 === "03" || value1 === "07" || value1 === "08") {
          mask = "## #### ####";
        } else {
          mask = "#### ### ###";
        }

        if (value1 === "13") {
          if (parseInt(value2) >= 1300 || value2 === "130" || value2 === "13 0") {
            mask = "#### ### ###";
          } else {
            mask = "## ## ##";
          }
        }
      }
      return mask;
    },
  },
};
</script>

